vip-card-opcao-faturamento {
  vip-radio-button div {
    width: 100%;
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  vip-radio-button {
    .vip-radio-circle {
      height: 1.25rem;
      width: 1.25rem;
    }

    .vip-radio-button-input {
      height: 1.25rem;
      width: 1.25rem;
    }
  }

  vip-radio-group vip-radio-button {
    @apply mb-2;
  }

  vip-radio-button .vip-radio-button-label {
    margin: auto 0;
  }
}
