$qrCodeSize: 180px;

vip-pagamento-carteira-digital-desktop {
  vip-image {
    img {
      width: 45px;
      height: 30px;
    }
  }

  vip-button {
    width: fit-content;

    &.fixed-width {
      width: $qrCodeSize;
    }
  }

  .qr-code {
    width: $qrCodeSize;
  }
}
.cancel-button .vip-button {
  border-color: var(--vip-color-error-main);
  color: var(--vip-color-error-main);
}
