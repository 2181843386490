vip-pagamento-carteira-digital-container {
  .display-stick {
    flex: 0 0 auto;
    position: sticky;
    top: 0;
    z-index: 0;
  }
  vip-image {
    img {
      width: 1.875rem;
      height: 1.125rem;
    }
  }
}
