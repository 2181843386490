$qrCodeSize: 180px;

vip-pagamento-carteira-digital {
  vip-image {
    img {
      width: 45px;
      height: 30px;
    }
  }

  vip-button {
    width: fit-content;

    &.fixed-width {
      width: $qrCodeSize;
    }
  }

  .qr-code {
    width: $qrCodeSize;
  }
}
