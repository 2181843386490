<div class="flex items-center mb-3">
  <i class="vip-color-secondary-main mr-2 text-xl icon-shopping_basket"></i>
  <p [ngClass]="{ 'text-lg font-bold': props, 'text-base': !props }">
    {{ title }}
  </p>
  <span class="text-xs ml-auto vip-color-secondary-default">{{
    produtos | qtdItensProdutos
  }}</span>
</div>
<hr *ngIf="props" class="mt-4 mb-3 -mx-4" />
<ng-container *ngFor="let produtoCompra of produtos; let last = last">
  <vip-card-resumo-produtos-item
    [produtoCompra]="produtoCompra"
  ></vip-card-resumo-produtos-item>
  <hr
    *ngIf="!last && props && isDesktopProps"
    class="mt-2 w-full"
    [ngClass]="{ 'mb-2': !last }"
  />
  <hr *ngIf="!last && !props" class="vip-divider my-1" />
</ng-container>
