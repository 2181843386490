vip-cartao-credito-desktop {
  .card-large {
    width: 8.125rem;
    height: 8.125rem;
    flex-shrink: 0;
  }

  .icon-color {
    color: #e6210f;
    font-size: 1.5rem;
  }

  .text {
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.4px;
  }

  .text-title {
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.15px;
  }
}
.vip-cartao-credito-desktop__novo-cartao {
  margin-left: 0;
}
