import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IProdutoCompra } from '@vip/core';

@Component({
  selector: 'vip-modal-resumo-produtos',
  templateUrl: './modal-resumo-produtos.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ModalResumoProdutosComponent {
  @Input() produtos: IProdutoCompra[] = [];
  @Input() title = 'Produtos';
  @Input() props = false;
  @Input() isDesktopProps = false;
}
