import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  BandeiraEnum,
  FormaPagamentoEnum,
  ICartao,
  IFormaPagamento,
  IPagamentosViewModel,
  IPortador,
  ISwitchOption,
} from '@vip/core';
import { Subscription, fromEvent } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Platform } from '@ionic/angular';
import { CentroDistribuicaoFacade } from '@vip/state/centro-distribuicao';

@UntilDestroy()
@Component({
  selector: 'vip-forma-pagamento',
  templateUrl: './forma-pagamento.component.html',
  styleUrls: ['./forma-pagamento.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class FormaPagamentoComponent implements OnInit, OnChanges, OnDestroy {
  private _desativaOnline = false;
  private _cartoes: ICartao[] = [];
  private touchstartX = 0;
  private touchendX = 0;
  private _pagamentos!: IPagamentosViewModel;

  readonly QTD_PARCELAS_DEFAULT = 1;
  swipeRigth = false;
  pagamentoOptions: Record<string, ISwitchOption> = {
    online: {
      label: 'Pagar Online',
      key: 'online',
      disabled: false,
      icon: '',
    },
    entrega: {
      label: 'Pagar na Entrega',
      key: 'entrega',
      disabled: false,
      icon: '',
    },
  };
  showOpcoesPagamento = {
    refeicao: false,
    credito: false,
    privateLabel: false,
  };
  cartoesExibidos: ICartao[] = [];
  showSwitch = true;
  tipoPagamento = '';
  windowSubscriber!: Subscription;
  BandeiraEnum = BandeiraEnum;
  FormaPagamentoEnum = FormaPagamentoEnum;
  carteiraDigital: IFormaPagamento[] = [];
  pagamentoPre: IFormaPagamento[] = [];
  pagamentoPos: IFormaPagamento[] = [];

  get selectedOptions(): boolean {
    if (this.formaPagamentoSelecionada || this.tipoPagamento) {
      return true;
    }
    return false;
  }

  @Input()
  pagamentosEntrega: IFormaPagamento[] = [];
  @Input()
  formaPagamentos: IFormaPagamento[] = [];
  @Input()
  hasPortadorValido = false;
  @Input()
  formaPagamentoSelecionada?: IFormaPagamento | null;
  @Input()
  cartaoSelecionadoId: string | null = null;
  @Input()
  permitirSalvarCartao = false;
  @Input()
  isLojaAutonoma = false;
  @Input()
  set desativaOnline(value: boolean) {
    this._desativaOnline = value;
    this.pagamentoOptions.online.disabled = value;
    if (value) this.pagamentoOptions.checked = this.pagamentoOptions.entrega;
  }
  get desativaOnline(): boolean {
    return this._desativaOnline;
  }
  @Input()
  set cartoes(value: ICartao[]) {
    this._cartoes = value;
    this.cartoesExibidos = value.slice(0, 3);
  }
  get cartoes() {
    return this._cartoes;
  }
  @Input()
  set pagamentos(value: IPagamentosViewModel) {
    this._pagamentos = value;
    this.carteiraDigital = this.pagamentos['online']['carteira_digital'] || [];
    this.pagamentoPre = this.pagamentos['online']['credito'] || [];
    this.pagamentoPos = this.pagamentos['entrega']['todos_pagamentos'] || [];
    this.exibirOpcoesPrePagas();
    this.verificarExibirSwitch();
  }
  get pagamentos() {
    return this._pagamentos;
  }

  @Output()
  formaPagamentoChange = new EventEmitter();
  @Output()
  portadorChange = new EventEmitter<IPortador | undefined>();
  @Output()
  cartaoSalvoChange = new EventEmitter<string | null>();
  @Output()
  deletarCartao = new EventEmitter<ICartao>();
  @Output()
  limparFormaPagamentoSelecionada = new EventEmitter();
  @Output()
  confirm = new EventEmitter();

  constructor(
    private router: Router,
    private platform: Platform,
  ) {
    this.pagamentoOptions.checked = this.pagamentoOptions.online;
  }

  ngOnInit(): void {
    this.initListenersBackNavigate();
    setTimeout(() => {
      this.pagamentos;
    }, 100);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['pagamentos'] && !changes['pagamentos'].firstChange) {
      if (this.pagamentoPre && this.pagamentoPos) {
        this.verificarExibirSwitch();
      }
    }

    if (changes['isLojaAutonoma'] && !changes['isLojaAutonoma'].firstChange) {
      this.verificarExibirSwitch();
    }
  }

  @HostListener('unloaded')
  ngOnDestroy(): void {
    this.windowSubscriber.unsubscribe();
  }

  private getFormaPagamentoPorCartaoSalvo(cartaoId: string): IFormaPagamento {
    const cartao = this.cartoes.find((cartao) => cartao.id === cartaoId);

    const [formaPagamento] = this.pagamentoPre.filter((pagamento) =>
      this.compareFormaPagamentoComCartao(pagamento, cartao),
    );

    return formaPagamento;
  }

  private compareFormaPagamentoComCartao(
    pagamento: IFormaPagamento,
    cartao: ICartao | undefined,
  ): boolean {
    return (
      pagamento.adquirente_id === cartao?.adquirente_id &&
      pagamento.bandeira?.toLowerCase() === cartao.bandeira.toLowerCase()
    );
  }

  private verificarExibirSwitch(): void {
    if (
      !this.pagamentoPre ||
      !this.pagamentoPos ||
      this.isLojaAutonoma === undefined
    ) {
      return;
    }

    const pagamentos = {
      pagamentoPre:
        this.showOpcoesPagamento['credito'] ||
        this.showOpcoesPagamento['refeicao'] ||
        this.showOpcoesPagamento['privateLabel'] ||
        this.carteiraDigital.length > 0 ||
        this.cartoes.length > 0,
      pagamentoPos: this.pagamentoPos.length,
      lojaAutonoma: this.isLojaAutonoma,
    };

    if (pagamentos['lojaAutonoma']) {
      this.showSwitch = false;
      this.pagamentoOptions.checked = this.pagamentoOptions.online;
    } else if (pagamentos['pagamentoPre'] && pagamentos['pagamentoPos'] === 0) {
      this.pagamentoOptions.checked = this.pagamentoOptions.online;
      this.showSwitch = false;
    } else if (!pagamentos['pagamentoPre'] && pagamentos['pagamentoPos'] > 0) {
      this.pagamentoOptions.checked = this.pagamentoOptions.entrega;
      this.showSwitch = false;
    } else {
      this.showSwitch = true;
    }
  }

  private exibirOpcoesPrePagas(): void {
    const { credito, voucher, cartao_da_loja } = this.pagamentos['online'];
    this.showOpcoesPagamento = {
      credito: credito ? credito.length > 0 : false,
      refeicao: voucher ? voucher.length > 0 : false,
      privateLabel: cartao_da_loja ? cartao_da_loja.length > 0 : false,
    };
  }

  goBack() {
    if (
      this.formaPagamentoSelecionada?.pagamento ==
        FormaPagamentoEnum.PRE_PAGO &&
      !this.hasPortadorValido
    ) {
      this.formaPagamentoChange.emit();
    }
    this.router.navigateByUrl('pagamento');
  }

  onConfirm(): void {
    this.swipeRigth = false;
    if (this.tipoPagamento) {
      this.router.navigateByUrl(`pagamento/cartao/${this.tipoPagamento}`);
      this.tipoPagamento = '';
      return;
    }
    if (
      this.cartoes.length > 0 &&
      !this.formaPagamentoSelecionada &&
      !this.cartaoSelecionadoId
    ) {
      this.formaPagamentoSelecionada = {
        pagamento: FormaPagamentoEnum.PRE_PAGO,
      } as IFormaPagamento;
    }

    if (this.isNewCard()) {
      this.formaPagamentoSelecionada = this.pagamentoPre[0];
      this.formaPagamentoChange.emit(this.pagamentoPre[0].id);
    } else if (this.cartaoSelecionadoId) {
      const formaPagamento = this.getFormaPagamentoPorCartaoSalvo(
        this.cartaoSelecionadoId,
      );
      const { id: formaPagamentoId } = formaPagamento;
      const portador: IPortador = {
        card_id: this.cartaoSelecionadoId,
        cc_brand: '',
        installments: this.QTD_PARCELAS_DEFAULT,
        criar_token: false,
        cc_cvv: '',
        cc_expire_month: '',
        cc_expire_year: '',
        cc_holder: '',
        cc_number: '',
        cpf_portador: '',
      };

      this.formaPagamentoSelecionada = formaPagamento;
      this.cartaoSalvoChange.emit(this.cartaoSelecionadoId);
      this.formaPagamentoChange.emit(formaPagamentoId);
      this.portadorChange.emit(portador);
    }

    if (
      this.formaPagamentoSelecionada &&
      (this.formaPagamentoSelecionada.carteira_digital ||
        this.formaPagamentoSelecionada.pagamento !==
          FormaPagamentoEnum.PRE_PAGO ||
        this.cartaoSelecionadoId)
    ) {
      this.confirm.emit(this.formaPagamentoSelecionada);
      this.router.navigateByUrl('pagamento');
    }
  }

  isNewCard(): boolean {
    return (
      (!this.formaPagamentoSelecionada?.carteira_digital &&
        this.formaPagamentoSelecionada?.pagamento ===
          FormaPagamentoEnum.PRE_PAGO &&
        !this.cartaoSelecionadoId) ||
      !!this.tipoPagamento
    );
  }

  selectCartaoSalvo(cartao: ICartao): void {
    this.cartaoSalvoChange.emit(cartao.id);
    this.formaPagamentoChange.emit(null);
  }

  removerDadosCartaoSalvo(): void {
    this.formaPagamentoChange.emit(null);
    this.cartaoSalvoChange.emit(null);
    this.limparFormaPagamentoSelecionada.emit();
  }

  handleVerMais(): void {
    if (this.cartoesExibidos.length === 3) {
      this.cartoesExibidos = this.cartoes;
    } else {
      this.cartoesExibidos = this.cartoes.slice(0, 3);
    }
  }

  private initListenersBackNavigate(): void {
    const isIos = this.platform.is('ios');

    if (isIos) {
      this.initIosListeners();
    } else {
      this.windowSubscriber = fromEvent(window, 'popstate')
        .pipe(
          untilDestroyed(this),
          filter(
            () =>
              this.formaPagamentoSelecionada?.pagamento ==
                FormaPagamentoEnum.PRE_PAGO && !this.hasPortadorValido,
          ),
        )
        .subscribe(() => {
          this.formaPagamentoChange.emit();
        });
    }
  }

  private initIosListeners(): void {
    this.windowSubscriber = new Subscription();

    this.windowSubscriber.add(
      this.router.events
        .pipe(
          untilDestroyed(this),
          filter(
            (event): event is NavigationEnd => event instanceof NavigationEnd,
          ),
        )
        .subscribe((event: NavigationEnd) => {
          if (this.swipeRigth && event.urlAfterRedirects == '/pagamento') {
            this.formaPagamentoChange.emit();
          }
        }),
    );

    this.windowSubscriber.add(
      fromEvent<TouchEvent>(document, 'touchstart')
        .pipe(
          untilDestroyed(this),
          filter(
            () =>
              this.formaPagamentoSelecionada?.pagamento ==
                FormaPagamentoEnum.PRE_PAGO && !this.hasPortadorValido,
          ),
        )
        .subscribe((event) => {
          this.touchstartX = event.changedTouches[0].screenX;
        }),
    );

    this.windowSubscriber.add(
      fromEvent<TouchEvent>(document, 'touchend')
        .pipe(
          untilDestroyed(this),
          filter(
            () =>
              this.formaPagamentoSelecionada?.pagamento ==
                FormaPagamentoEnum.PRE_PAGO && !this.hasPortadorValido,
          ),
        )
        .subscribe((event) => {
          this.touchendX = event.changedTouches[0].screenX;
          this.swipeRigth = this.touchstartX < this.touchendX;
        }),
    );
  }
}
