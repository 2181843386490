import {
  AfterViewChecked,
  Directive,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  BandeiraEnum,
  environment,
  ICompra,
  ICompraDesconto,
  ICompraPagamento,
  ITaxaServico,
  ShareService,
} from '@vip/core';
import { CountdownBarComponent } from '@vip/ui/countdown-bar';
import { IMessage, MessageService } from '@vip/ui/message';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { addMinutes, differenceInSeconds } from 'date-fns';

@UntilDestroy()
@Directive()
export class PagamentoCarteiraDigitalBaseDirective
  implements AfterViewChecked, OnDestroy, OnInit
{
  ePix = false;
  tempoRestante = 0;
  tempoLimitePagamento = environment.toggleNovaShipay ? 600 : 300;
  subscriptionCountdownBar?: Subscription;
  private _compraPagamento!: ICompraPagamento | null;
  get compraPagamento(): ICompraPagamento | null {
    return this._compraPagamento;
  }
  set compraPagamento(value: ICompraPagamento | null) {
    this._compraPagamento = value;
    if (value) {
      this.definirSeEPix(value);
      this.setTempoRestante();
    }
  }
  private _compraEmProcesso!: ICompra | null;
  get compraEmProcesso() {
    return this._compraEmProcesso;
  }
  @Input()
  set compraEmProcesso(value: ICompra | null) {
    this._compraEmProcesso = value;
    if (value) {
      this.compraPagamento =
        this._compraEmProcesso &&
        this._compraEmProcesso.compra_pagamentos.length
          ? this._compraEmProcesso.compra_pagamentos[0]
          : null;
    }
  }
  @Input()
  descontos?: ICompraDesconto[];
  @Input() taxaServico: ITaxaServico | null = null;
  @Input() valorDescontos?: number;
  @Input() tempoEsgotado = false;
  @Input() loading = false;
  @Input() currentDate: string | null = null;

  @Output()
  clickedCopy = new EventEmitter();

  @Output()
  clickedDeeplink = new EventEmitter();

  @Output()
  clickedCancelarPagamento = new EventEmitter();

  @Output()
  clickedTentarNovamente = new EventEmitter();

  @Output()
  tempoExpirado = new EventEmitter();

  @Output()
  goBack = new EventEmitter();

  @Output()
  timerIsRunning = new EventEmitter<boolean>();

  @ViewChild(CountdownBarComponent)
  countdownBar?: CountdownBarComponent;

  constructor(
    private messageService: MessageService,
    private shareService: ShareService,
  ) {}

  ngOnInit(): void {
    window.addEventListener('focus', () => this.handleWindowFocus());
  }

  ngOnDestroy(): void {
    window.removeEventListener('focus', () => this.handleWindowFocus());

    if (this.subscriptionCountdownBar)
      this.subscriptionCountdownBar.unsubscribe();
  }

  ngAfterViewChecked(): void {
    if (this.subscriptionCountdownBar) return;
    if (this.countdownBar)
      this.subscriptionCountdownBar = this.countdownBar.decreased
        .pipe(untilDestroyed(this), take(1))
        .subscribe(() => this.timerIsRunning.emit(true));
  }

  definirSeEPix(compraPagamento: ICompraPagamento): void {
    this.ePix = compraPagamento.forma_pagamento.bandeira === BandeiraEnum.PIX;
  }

  setTempoRestante(): void {
    let tempoRestante = 0;
    if (this.compraPagamento?.created) {
      const tempoEmMinutos = environment.toggleNovaShipay ? 10 : 5;
      const tempoCriacao = new Date(this.compraPagamento.created);
      const tempoLimite = addMinutes(tempoCriacao, tempoEmMinutos);
      const now = this.currentDate ? new Date(this.currentDate) : new Date();
      tempoRestante = differenceInSeconds(tempoLimite, now);
    }
    this.tempoRestante = tempoRestante;
  }

  copiarCodigo() {
    this.shareService.shareLinkOnDesktop(
      this.compraPagamento?.qr_code_text as string,
    );
    const messageData: IMessage = {
      icon: 'icon-check',
      position: 'top',
      type: 'success',
    };
    this.messageService.openMessage(messageData, 'Código copiado!', 0.5);
    this.clickedCopy.emit(this.compraPagamento?.qr_code_text);
  }

  handleTempoEsgotado() {
    this.tempoEsgotado = true;
    this.tempoExpirado.emit(this.compraPagamento);
  }

  private handleWindowFocus(): void {
    this.setTempoRestante();
    if (this.tempoRestante <= 0) {
      this.handleTempoEsgotado();
    }
  }
}
