vip-pagamento-cartao {
  vip-image {
    img {
      max-height: 1.5625rem;
    }
  }

  .vip-credit-card,
  .vip-cartao-bandeira-wrapper {
    @apply flex justify-center items-center shadow overflow-hidden rounded;
    width: 2.375rem;
    height: 1.5625rem;
    padding: 0.0625rem;
  }

  .vip-credit-card {
    min-width: 38px;

    &:not(:last-child) {
      @apply mr-3;
    }
  }

  .vip-cartao-bandeira-wrapper {
    @apply absolute;
    top: 0.6875rem;
    right: 2.625rem;

    &.input-numero-cartao {
      @apply right-3;
    }
  }

  .vip-grid-cols-desktop {
    grid-template-columns: repeat(2, minmax(40%, 50%));
  }

  @media screen and (max-width: 1199px) {
    .vip-grid-cols-desktop {
      grid-template-columns: auto;
    }

    .relative-on-mobile {
      position: relative;
    }
  }
}
