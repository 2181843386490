import { ModalService } from '@vip/ui/modal';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  ICartao,
  ICodigoPromocional,
  ICompraDesconto,
  ICupomCredito,
  IFormaPagamento,
  IPagamento,
  ITaxaServico,
  IPortador,
  StepStatusEnum,
  IProdutoCompra,
  LayoutUtilsService,
} from '@vip/core';
import { OpcoesPagamentoComponent } from '@vip/ui/opcoes-pagamento';
import { PagamentoCartaoDirective } from '@vip/views/pagamento';
import { ModalResumoProdutosComponent } from '@vip/views/resumo-compra';

@Component({
  selector: 'vip-pagamento-desktop',
  templateUrl: './pagamento-desktop.component.html',
  styleUrls: ['./pagamento-desktop.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class PagamentoDesktopComponent extends PagamentoCartaoDirective {
  @ViewChild(OpcoesPagamentoComponent)
  opcoesPagamentoComponent!: OpcoesPagamentoComponent;
  @Input() valorAcrescimos?: number;
  @Input() exibeCashback?: boolean | null = false;
  @Input() saldoCashback?: number | null;
  @Input() fallbackImageUrl?: string;
  steps = [
    { title: 'Entrega', link: '', status: StepStatusEnum.COMPLETED },
    { title: 'Pagamento', link: '', status: StepStatusEnum.ACTIVE },
    { title: 'Revisão', link: '', status: StepStatusEnum.PENDING },
  ];
  @Input() produtoCompraProps: IProdutoCompra[] = [];

  private pagamentoPre: IFormaPagamento[] = [];
  @Input() disablePagamentoButton = false;
  private _pagamentosOnline!: IPagamento;
  readonly QTD_PARCELAS_DEFAULT = 1;

  @Input() set pagamentosOnline(pagamentos: IPagamento | null) {
    if (pagamentos) {
      this.pagamentoPre =
        pagamentos.tipos.find((tipo) => tipo.titulo === 'credito')
          ?.disponiveis || [];
      this._pagamentosOnline = pagamentos;
    }
  }
  get pagamentosOnline() {
    return this._pagamentosOnline;
  }
  @Input() pagamentosEntrega!: IPagamento | null;
  @Input() taxaEntrega?: number;
  @Input() descontos?: ICompraDesconto[];
  @Input() taxaServico: ITaxaServico | null = null;
  @Input() removerPrepagos = false;
  @Input() loadedPagamentos = false;
  @Input() logoSrc = '';
  @Input() url_google = '';
  @Input() cartoes?: ICartao[];
  @Input() cartaoSelecionadoId?: string | null;
  @Input()
  totalCompra!: number;
  @Input()
  valorDescontos = 0;
  @Input()
  cupons: ICupomCredito[] = [];
  @Input()
  cuponsSelecionados: ICupomCredito[] = [];
  @Input()
  codigoPromocional: ICodigoPromocional | null = null;
  @Input()
  isTelevendas = false;

  @Output()
  ativarCodigo = new EventEmitter<string>();
  @Output()
  deleteCodigoPromocionalClick = new EventEmitter<string>();
  @Output()
  changedValorUtilizadoCashback = new EventEmitter<number>();
  @Output()
  concluirCompraClicked = new EventEmitter<void>();
  @Output()
  continuarComprando = new EventEmitter<void>();
  @Output()
  portadorChange = new EventEmitter<IPortador | undefined>();
  @Output()
  buscarBandeiraCartao = new EventEmitter();
  @Output() setValorTroco = new EventEmitter<number>();

  isDeskTop = this.layoutUtilsService.isDesktop();

  constructor(
    private modalService: ModalService<ModalResumoProdutosComponent>,
    private layoutUtilsService: LayoutUtilsService,
  ) {
    super();
  }

  handleAlterarCashback(valorCashback: number): void {
    this.changedValorUtilizadoCashback.emit(valorCashback);
  }

  private getFormaPagamentoPorCartaoSalvo(cartaoId: string): IFormaPagamento {
    const cartao = this.cartoes?.find((cartao) => cartao.id === cartaoId);

    const [formaPagamento] = this.pagamentoPre.filter((pagamento) =>
      this.compareFormaPagamentoComCartao(pagamento, cartao),
    );

    return formaPagamento;
  }

  private compareFormaPagamentoComCartao(
    pagamento: IFormaPagamento,
    cartao: ICartao | undefined,
  ): boolean {
    return (
      pagamento.adquirente_id === cartao?.adquirente_id &&
      pagamento.bandeira?.toLowerCase() === cartao.bandeira.toLowerCase()
    );
  }

  selectCartaoSalvo(cartaoId: string | null): void {
    if (!cartaoId) {
      this.cartaoSalvoChange.emit(null);
      this.portadorChange.emit(undefined);
      this.formaPagamentoChange.emit(null);
      return;
    }
    this.cartaoSelecionadoId = cartaoId;
    const formaPagamento = this.getFormaPagamentoPorCartaoSalvo(
      this.cartaoSelecionadoId,
    );
    const { id: formaPagamentoId } = formaPagamento;
    const portador: IPortador = {
      card_id: this.cartaoSelecionadoId,
      cc_brand: '',
      installments: this.QTD_PARCELAS_DEFAULT,
      criar_token: false,
      cc_cvv: '',
      cc_expire_month: '',
      cc_expire_year: '',
      cc_holder: '',
      cc_number: '',
      cpf_portador: '',
    };
    this.formaPagamentoSelecionada = formaPagamento;
    this.cartaoSalvoChange.emit(this.cartaoSelecionadoId);
    this.portadorChange.emit(portador);
    this.formaPagamentoChange.emit(formaPagamentoId);
  }

  verItens() {
    const modalRef = this.modalService.openModal(ModalResumoProdutosComponent, {
      bottom: false,
    });

    if (modalRef) {
      (modalRef.instance as ModalResumoProdutosComponent).produtos =
        this.produtoCompraProps;
      (modalRef.instance as ModalResumoProdutosComponent).title =
        'Produtos com Desconto';
      (modalRef.instance as ModalResumoProdutosComponent).props = true;
      (modalRef.instance as ModalResumoProdutosComponent).isDesktopProps =
        this.isDeskTop;
    }
  }
}
